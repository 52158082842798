import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, from, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private auth: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.auth.ensureApiToken(req.url)).pipe(
      switchMap((apiToken) => {
        if (apiToken) {
          // Clone the original request and return with the added token.
          const tokenizedReq = req.clone({
            setHeaders: { access_token: `Bearer ${apiToken}` },
          });
          return next.handle(tokenizedReq);
        }
        else {
          // Pass the original request if no token is generated.
          return next.handle(req);
        }
      }),
      catchError((err) => {
        console.error("Error in AuthInterceptor:", err);
        return throwError(err);
      })
    );
  }
}
