// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  UNIQUE_KEY: 'aws_gateway_access_token_api',
  DEV_CALL_API_URL: 'https://src-metadataapi.mosaic-dev.siemens-energy.cloud/v1/callApi',
  QA_CALL_API_URL: 'https://src-metadataapi.mosaic-qa.siemens-energy.cloud/v1/callApi',
  PROD_CALL_API_URL: 'https://src-metadataapi.mosaic.siemens-energy.cloud/v1/callApi',
  DEV_GATEWAY_ID: "i86d3893n3",
  QA_GATEWAY_ID: "mofewrj3xh",
  PROD_GATEWAY_ID: "wy6uxkbuwf"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
