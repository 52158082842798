import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { AuthParams } from "../../model/enums/auth-params.enum";
import { Login } from "../../model/enums/login.enum";
import { LoginConstant } from "../../model/login-constant";
import { OktaConstants } from "../../model/okta-constants";
import { OktaAuthenticator } from "../../services/auth/okta-authenticator";
import { BehaviorSubject, Observable } from "rxjs";
import { AuthBase } from "./auth-base";
import { AzureAuthenticator } from "./azure-authenticator";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  public doRedirect = true;
  private authenticator: AuthBase;
  private readonly loginStatus: BehaviorSubject<Login>;
  private router: Router;
  redirectUrl = "";
  fetchingToken: boolean = false;

  constructor(private http: HttpClient, private authClient: MsalService) {
    // TODO: from here we can comment out and use init method if want to initialize other authenticator
    this.loginStatus = new BehaviorSubject<Login>(Login.LOGIN_FAILED);
    this.authenticator = new AzureAuthenticator(authClient);
    //this.authenticator = new OktaAuthenticator(OktaConstants);
  }

  set landingPage(routePath: string) {
    this.redirectUrl = routePath;
  }

  /**
   * This method is used to set router properties.
   * @param value: Router instance.
   */
  set route(value: Router) {
    this.router = value;
  }

  /**
   * This method returns a behaviour subject which will be executed on every login status.
   */
  get authStatus(): BehaviorSubject<Login> {
    return this.loginStatus;
  }

  /**
   * This method can be used to initiate different authenticator based on the param
   * passed.
   */
  init(param: AuthParams) {
    switch (param) {
      case AuthParams.OKTA_LOGIN:
        this.authenticator = new OktaAuthenticator(OktaConstants);
        break;
      case AuthParams.AZURE_LOGIN:
        this.authenticator = new AzureAuthenticator(this.authClient);
        break;
      case AuthParams.OTHER_LOGIN:
        this.authenticator = new OktaAuthenticator(OktaConstants);
        break;
      default:
        this.authenticator = new OktaAuthenticator(OktaConstants);
    }
  }

  /**
   * Returns access token.
   */
  async getAccessToken() {
    return this.authenticator.getAccessToken();
  }

  /**
   * Return the ID token
   */
  async getIdToken() {
    return this.authenticator.getIdToken();
  }

  /**
   * Check if user is authenticated or not
   * @returns True if user is authenticated otherwise false
   */
  async isAuthenticated() {
    return !!(await this.authenticator.isAuthenticated());
  }

  /**
   * Return the payload of access token
   */
  getTokenPayload() {
    return this.authenticator.getTokenPayload();
  }

  /**
   * This method is used to do login with okta services.
   */
  login() {
    return this.authenticator.login();
  }

  /**
   * This method is used to do logout with okta services.
   */
  logout(): Observable<any> {
    return new Observable<any>((observer) => {
      this.authenticator.logout().then(
        (status) => {
          this.loginStatus.next(Login.LOG_OUT_SUCCESS);
          if (this.doRedirect) {
            this.router.navigate([LoginConstant.DEFAULT_LOGOUT_REDIRECT]);
          } else {
            observer.next(status);
          }
        },
        (error) => {
          this.loginStatus.next(Login.LOG_OUT_FAILED);
          observer.next(error);
        }
      );
    });
  }

  /**
   * Parse the token from the URL and save it in the session. This method should be called by Main application
   * when redirect occurs
   */
  handleAuthRedirect(): Observable<any> {
    return new Observable<any>((observer) => {
      this.authenticator.handleAuthRedirect().then(
        (status) => {
          observer.next(status);
          // TODO: We need to check for user in DB from here.
          this.loginStatus.next(Login.LOGIN_SUCCESS);
        },
        (error) => {
          observer.next(error);
          this.loginStatus.next(Login.LOGIN_FAILED);
        }
      );
    });
  }

  /**
   * Redirect the page for login via entitlement.
   */
  private authenticate() {
    this.authenticator.authenticate();
  }

  isApiTokenValid(): boolean {
    const token = localStorage.getItem("apiToken");
    const expiry = localStorage.getItem("apiTokenExpiry");
    const now = Math.floor(Date.now() / 1000);

    if (!token) {
      console.log("No API token in localStorage");
      return false;
    }

    if (!expiry || Number(expiry) <= now) {
      console.log("API token expired");
      return false;
    }
    return true;

  }
  async ensureApiToken(reqUrl): Promise<string> {
    if (this.isApiTokenValid()) {
      return localStorage.getItem("apiToken")!;
    }

    // Use a lock to prevent multiple simultaneous calls
    if (this.fetchingToken) {
      return new Promise((resolve, reject) => {
        const interval = setInterval(() => {
          if (!this.fetchingToken) {
            clearInterval(interval);
            resolve(localStorage.getItem("apiToken") || "");
          }
        }, 100); // Check every 100ms
      });
    }

    this.fetchingToken = true;
    try {
      let authUrl = this.getAuthUrl(reqUrl);
      if (authUrl) {
        const token = await this.fetchApiToken(authUrl);
        console.log("New token generated");
        this.fetchingToken = false;
        return token;
      }
      else {
        this.fetchingToken = false;
        return null;
      }
    } catch (error) {
      console.error("Failed to fetch API token:", error);
      this.fetchingToken = false;
      throw error;
    }
  }

  async fetchApiToken(authUrl): Promise<string> {
    try {
      const body = new URLSearchParams({
        api_key: environment.UNIQUE_KEY
      });
      console.log("Fetching new token..");
      const response = await fetch(authUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: body.toString(),
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch token: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      const { access_token, expires_in } = data;
      if (access_token) {
        const expiry = Math.floor(Date.now() / 1000) + expires_in;
        // Store the token and expiry in localStorage
        localStorage.setItem("apiToken", access_token);
        localStorage.setItem("apiTokenExpiry", expiry.toString());
        return access_token;
      } else {
        throw new Error("No access token returned from API");
      }
    } catch (error) {
      console.error("Failed to fetch API token:", error);
      throw new Error(`Failed to fetch API token: ${error}`);
    }
  }

  getAuthUrl(reqUrl: string): string {
    if (reqUrl.includes(environment.QA_GATEWAY_ID)) {
      return environment.QA_CALL_API_URL;
    } else if (reqUrl.includes(environment.PROD_GATEWAY_ID)) {
      return environment.PROD_CALL_API_URL;
    } else if (reqUrl.includes(environment.DEV_GATEWAY_ID)) {
      return environment.DEV_CALL_API_URL;
    } else return null;
  }

}